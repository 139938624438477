<template>
  <div>
    <!-- Navbar Start -->
    <nav class="navbar navbar-landing navbar-expand-md fixed-top">
      <div class="container">
        <router-link to="/" class="btn btn-sm btn-outline-light"
          >Kembali</router-link
        >
        <!-- Brand -->
        <!--        <img-->
        <!--          src="https://psb.nuruljadid.net/img/logo.0367ed5b.png"-->
        <!--          alt=""-->
        <!--          width="40"-->
        <!--          class="mr-3"-->
        <!--        />-->
        <div class="navbar-brand d-none d-lg-inline-block"
          >Formulir Pendaftaran</div
        >
        <div class="navbar-brand d-lg-none"
          >Formulir</div
        >

        <!-- Navbar links -->
        <div>
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <div v-if="!success.status">
                <button class="btn btn-sm btn-default my-2 my-sm-0" @click="generateToken"
                        :class="{ 'text-danger': !recaptcha_token, 'text-light': recaptcha_token }">
                  <fa-icon v-if="recaptcha_token" :icon="['fas', 'check-circle']"/>
                  <fa-icon v-if="!recaptcha_token" :icon="['fas', 'exclamation-circle']"/>
                </button>

                <button @click="doRegister" class="btn btn-sm btn-light my-2 my-sm-0">Daftar</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Navbar End -->

    <!--  Form Register Start  -->
    <form @submit.prevent class="container px-0 px-lg-3 form-margin-none" style="margin-top: 100px" v-if="!success.status">
      <div class="alert alert-info" role="alert" v-if="show_info">
        <ul class="mb-0">
          <li>Jika terdapat pesan `Terjadi Kesalahan Captcha verification is failed` maka coba klik indikator check (pojok kanan) atas, dan coba Kirim kembali.</li>
        </ul>
      </div>

      <div class="mb-4" style="border: .1em dashed #cacaca;">
        <!-- Biodata Pesertadidik -->
      <div class="card mb-4" style="border: none;">
        <h6 style="border-radius: unset;border: none;" class="card-header ">Biodata Pesertadidik</h6>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">

              <div>
                <div class="form-group">
                  <div class="row">
                    <legend class="col-form-label col-sm-2 pt-0">Kewarganegaraan</legend>
                    <div class="col-sm-10">
                      <div class="form-check form-check-inline">
                        <input :disabled="readOnly" name="warganegara" v-validate="'required'" v-model="biodata.warganegara" class="form-check-input" type="radio" id="wni" value="WNI">
                        <label class="form-check-label" for="wni">WNI</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input :disabled="readOnly" name="warganegara" v-model="biodata.warganegara" class="form-check-input" type="radio" id="wna" value="WNA">
                        <label class="form-check-label" for="wna">WNA</label>
                      </div>
                      <div v-show="errors.has('warganegara')" class="invalid-feedback" v-text="errors.first('warganegara')"></div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="no_passport" class="col-sm-2 col-form-label">Nomor Passport</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-md-3">
                        <input :readonly="readOnly" :disabled="biodata.warganegara == 'WNI'" name="No Passport" v-validate="'required'" :class="{ 'is-invalid': errors.has('No Passport') }" v-model="biodata.no_passport" type="text" class="form-control form-control-sm" id="no_passport" placeholder="Nomor Passport" autocomplete="">
                        <div v-show="errors.has('No Passport')" class="invalid-feedback" v-text="errors.first('No Passport')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="nokk" class="col-sm-2 col-form-label">Nomor KK</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-md-3">
                        <input :readonly="readOnly" :disabled="biodata.warganegara == 'WNA'" name="No.KK" v-validate="'required|numeric|digits:16'" :class="{ 'is-invalid': errors.has('No.KK') }" v-model="biodata.nokk" type="text" class="form-control form-control-sm" id="nokk" placeholder="Nomor Kartu Keluarga" autocomplete="">
                        <div v-show="errors.has('No.KK')" class="invalid-feedback" v-text="errors.first('No.KK')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="nik" class="col-sm-2 col-form-label">NIK</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-md-3">
                        <input :readonly="readOnly" :disabled="biodata.warganegara == 'WNA'" name="NIK" v-validate="'required|numeric|digits:16'" :class="{ 'is-invalid': errors.has('NIK') }" v-model="biodata.nik" type="text" class="form-control form-control-sm" id="nik" placeholder="Nomor Induk Kependudukan" autocomplete="">
                        <div v-show="errors.has('NIK')" class="invalid-feedback" v-text="errors.first('NIK')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="nama_lengkap" class="col-sm-2 col-form-label">Nama Lengkap</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-md-6">
                        <input :readonly="readOnly" name="nama" v-validate="'required'" :class="{ 'is-invalid': errors.has('nama') }" v-model="biodata.nama_lengkap" autocomplete="name" type="text" class="form-control form-control-sm" id="nama_lengkap" placeholder="Nama Lengkap">
                        <div v-show="errors.has('nama')" class="invalid-feedback" v-text="errors.first('nama')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <legend class="col-form-label col-sm-2 pt-0">Jenis Kelamin</legend>
                    <div class="col-sm-10">
                      <div class="form-check form-check-inline">
                        <input :readonly="readOnly" @change="onJkChange()" name="jenis kelamin" :disabled="biodata.uuid" v-validate="'required|included:L,P'" v-model="biodata.jenis_kelamin" class="form-check-input" type="radio" id="perempuan" value="P">
                        <label class="form-check-label" for="perempuan">
                          Perempuan
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input :readonly="readOnly" @change="onJkChange()" name="jenis kelamin" :disabled="biodata.uuid" v-model="biodata.jenis_kelamin" class="form-check-input" type="radio" id="laki-laki" value="L">
                        <label jenis class="form-check-label" for="laki-laki">
                          Laki-Laki
                        </label>
                      </div>
                      <div v-show="errors.has('jenis kelamin')" class="invalid-feedback" v-text="errors.first('jenis kelamin')"></div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="tempat_lahir" class="col-sm-2 col-form-label">Tempat Lahir</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-md-4">
                        <input :readonly="readOnly" name="Tempat Lahir" v-validate="'required|min:3'"  :class="{ 'is-invalid': errors.has('Tempat Lahir') }" v-model="biodata.tempat_lahir" type="text" class="form-control form-control-sm" id="tempat_lahir" placeholder="Tempat Lahir">
                        <div v-if="errors.has('Tempat Lahir')" class="invalid-feedback" v-text="errors.first('Tempat Lahir')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="tanggal_lahir" class="col-sm-2 col-form-label">Tanggal Lahir</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-auto">
                        <tanggal :readonly="readOnly" name="tanggal lahir" v-validate="'required'" :class="{ 'is-invalid': errors.has('tanggal lahir') }" v-model="biodata.tanggal_lahir" type="date" class="form-control form-control-sm" id="tanggal_lahir"/>
                        <div v-if="errors.has('tanggal lahir')" class="invalid-feedback" v-text="errors.first('tanggal lahir')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="anak_ke" class="col-sm-2 col-form-label">Anak Ke</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-md-1">
                        <label class="sr-only" for="anak_ke">Anak ke</label>
                        <input :readonly="readOnly" name="anak ke" v-validate="'required|numeric'" :class="{ 'is-invalid': errors.has('anak ke') }" v-model="biodata.anak_ke" type="number" class="form-control form-control-sm mb-2" id="anak_ke" min="0">
                        <div v-show="errors.has('anak ke')" class="invalid-feedback" v-text="errors.first('anak ke')"></div>
                      </div>
                      <div class="col-auto">
                        <div class="mb-2">
                          <label class="form-check-label" for="jum_saudara"> Dari </label>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <label class="sr-only" for="jum_saudara">Jumlah Saudara</label>
                        <input :readonly="readOnly" name="jumlah saudara" v-validate="'required|numeric'" :class="{ 'is-invalid': errors.has('jumlah saudara') }" v-model="biodata.jum_saudara" type="number" class="form-control form-control-sm mb-2" id="jum_saudara" min="0">
                        <div v-show="errors.has('jumlah saudara')" class="invalid-feedback" v-text="errors.first('jumlah saudara')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr/>

                <div class="form-group row">
                  <label for="tinggal_bersama" class="col-sm-2 col-form-label">Tinggal Bersama</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-md-4">
                        <input :readonly="readOnly" v-model="biodata.tinggal_bersama" type="text" class="form-control form-control-sm" id="tinggal_bersama" placeholder="Tinggal Bersama">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="pendidikan_terakhir" class="col-sm-2 col-form-label">Pendidikan Terakhir</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-md-4">
                        <input :readonly="readOnly" v-model="biodata.pendidikan_terakhir" type="text" class="form-control form-control-sm" id="pendidikan_terakhir" placeholder="Nama Pendidikan/Sekolah Terakhir">
                      </div>
                    </div>
                  </div>
                </div>

                <hr/>

                <div class="form-group row">
                  <label for="negara" class="col-sm-2 col-form-label">Negara</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-auto">
                        <select :disabled="readOnly" name="negara" v-validate="'required'" :class="{ 'is-invalid': errors.has('negara') }" v-model="biodata.id_negara" @change="handleNegara" id="negara" class="form-control form-control-sm">
                          <option :value="null">Pilih Negara</option>
                          <option v-for="(negara, id) in alamat.negara" :key="id" :value="negara.id" v-text="negara.nama"></option>
                        </select>
                        <div v-show="errors.has('negara')" class="invalid-feedback" v-text="errors.first('negara')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="provinsi" class="col-sm-2 col-form-label">Provinsi</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-auto">
                        <select name="provinsi" v-validate="'required'" :class="{ 'is-invalid': errors.has('provinsi') }" v-model="biodata.id_provinsi" :disabled="(biodata.id_negara == null ? true:false) || readOnly" @change="handleProvinsi" id="provinsi" class="form-control form-control-sm">
                          <option :value="null">Pilih Provinsi</option>
                          <option v-for="(provinsi, id) in alamat.provinsi" :key="id" v-text="provinsi.nama" :value="provinsi.id"></option>
                        </select>
                        <div v-show="errors.has('provinsi')" class="invalid-feedback" v-text="errors.first('provinsi')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="kabupaten" class="col-sm-2 col-form-label">Kabupaten</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-auto">
                        <select name="kabupaten" v-validate="'required'" :class="{ 'is-invalid': errors.has('kabupaten') }" v-model="biodata.id_kabupaten" :disabled="(biodata.id_provinsi == null ? true:false) || readOnly" @change="handleKabupaten" id="kabupaten" class="form-control form-control-sm">
                          <option :value="null">Pilih Kabupaten</option>
                          <option v-for="(kabupaten, id) in alamat.kabupaten" :key="id" v-text="kabupaten.nama" :value="kabupaten.id"></option>
                        </select>
                        <div v-show="errors.has('kabupaten')" class="invalid-feedback" v-text="errors.first('kabupaten')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="kecamatan" class="col-sm-2 col-form-label">Kecamatan</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-auto">
                        <select name="kecamatan" v-validate="''" :class="{ 'is-invalid': errors.has('kecamatan') }" v-model="biodata.id_kecamatan" :disabled="(biodata.id_kabupaten == null ? true:false) || readOnly" id="kecamatan" class="form-control form-control-sm">
                          <option :value="null">Pilih Kecamatan</option>
                          <option v-for="(kecamatan, id) in alamat.kecamatan" :key="id" :value="kecamatan.id" v-text="kecamatan.nama"></option>
                        </select>
                        <div v-show="errors.has('kecamatan')" class="invalid-feedback" v-text="errors.first('kecamatan')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr/>

                <div class="form-group row">
                  <label for="jalan" class="col-sm-2 col-form-label">Jalan</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-md-6">
                        <input :readonly="readOnly" name="jalan" v-validate="'required|min:6'" :class="{ 'is-invalid': errors.has('jalan') }" v-model="biodata.jalan" type="text" class="form-control form-control-sm" id="jalan" placeholder="Jalan atau Detail Alamat">
                        <div v-show="errors.has('jalan')" class="invalid-feedback" v-text="errors.first('jalan')"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="kodepos" class="col-sm-2 col-form-label">Kode POS</label>
                  <div class="col-sm-10">
                    <div class="form-row align-items-center">
                      <div class="col-auto">
                        <input :readonly="readOnly" name="kode pos" v-validate="'required|min:5'" :class="{ 'is-invalid': errors.has('kode pos') }" v-model="biodata.kodepos" autocomplete="postal-code" type="text" class="form-control form-control-sm" id="kodepos" placeholder="Kode Pos">
                        <div v-show="errors.has('kode pos')" class="invalid-feedback" v-text="errors.first('kode pos')"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Rencana Pendidikan -->
      <div class="card mb-4" style="border: none;">
        <h6 style="border-radius: unset;border: none;" class="card-header ">Rencana Pendidikan</h6>
        <div class="card-body">

          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-1">
                <div class="row">
                  <legend class="col-form-label col-sm-2 pt-0">Mondok</legend>
                  <div class="col-sm-10">
                    <div class="form-check form-check-inline">
                      <input :readonly="readOnly" name="mondok" v-validate="'required|included:Y,T'" v-model="biodata.pesertadidik.is_mondok" class="form-check-input" type="radio" id="ya" value="Y">
                      <label class="form-check-label" for="ya">
                        Ya
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input :readonly="readOnly" name="mondok" v-model="biodata.pesertadidik.is_mondok" class="form-check-input" type="radio" id="tidak" value="T">
                      <label jenis class="form-check-label" for="tidak">
                        Tidak
                      </label>
                    </div>
                    <div v-show="errors.has('mondok')" class="invalid-feedback" style="display: block;" v-text="errors.first('mondok')"></div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="jenis_pendaftaran" class="col-sm-2 col-form-label">Jenis Pendaftaran</label>
                <div class="col-sm-10">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <select name="Jenis Pendaftaran" v-validate="'required'" :class="{ 'is-invalid': errors.has('Jenis Pendaftaran') }" v-model="biodata.pesertadidik.jenis_daftar" :disabled="readOnly" id="jenis_pendaftaran" class="form-control form-control-sm">
                        <option :value="null">Pilih Jenis Pendaftaran</option>
                        <option :value="'BARU'">Baru</option>
                        <option :value="'MUTASI'">Mutasi</option>
                      </select>
                      <div v-show="errors.has('Jenis Pendaftaran')" class="invalid-feedback" v-text="errors.first('Jenis Pendaftaran')"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="lembaga" class="col-sm-2 col-form-label">Lembaga</label>
                <div class="col-sm-10">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <select name="Lembaga" v-validate="(biodata.pesertadidik.is_mondok == 'T' ? 'required':'')" :class="{ 'is-invalid': errors.has('Lembaga') }" v-model="biodata.pesertadidik.pendidikan.id_lembaga" :disabled="readOnly" id="lembaga" class="form-control form-control-sm" @change="handleLembaga('new')">
                        <option :value="null">Pilih Lembaga</option>
                        <option v-for="(lembaga, id) in pendidikan.lembaga" :key="id" :value="lembaga.id" v-text="lembaga.nama"></option>
                      </select>
                      <div v-show="errors.has('Lembaga')" class="invalid-feedback" v-text="errors.first('Lembaga')"></div>
                      <label for="email" style="color: #0c5460; font-size: 11px; font-style: italic;" >Bagi mahasiswa baru yang mondok untuk mendaftar di <a href="https://pmb.unuja.ac.id/" target="_blank" class="font-weight-bold">PMB UNUJA</a></label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="jurusan" class="col-sm-2 col-form-label">Jurusan</label>
                <div class="col-sm-10">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <select name="Jurusan" v-validate="''" :class="{ 'is-invalid': errors.has('Jurusan') }" v-model="biodata.pesertadidik.pendidikan.id_jurusan" :disabled="readOnly" id="jurusan" class="form-control form-control-sm">
                        <option :value="null">Pilih Jurusan</option>
                        <option v-for="(jurusan, id) in pendidikan.jurusan" :key="id" :value="jurusan.id" v-text="jurusan.nama"></option>
                      </select>
                      <div v-show="errors.has('Jurusan')" class="invalid-feedback" v-text="errors.first('Jurusan')"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="form-group row">
                <label for="kelas" class="col-sm-2 col-form-label">Kelas</label>
                <div class="col-sm-10">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <select name="Kelas" v-validate="''" :class="{ 'is-invalid': errors.has('Kelas') }" v-model="biodata.pesertadidik.pendidikan.id_kelas" :disabled="readOnly" id="kelas" class="form-control form-control-sm">
                        <option :value="null">Pilih Kelas</option>
                        <option v-for="(kelas, id) in pendidikan.kelas" :key="id" :value="kelas.id" v-text="kelas.nama"></option>
                      </select>
                      <div v-show="errors.has('Kelas')" class="invalid-feedback" v-text="errors.first('Kelas')"></div>
                    </div>
                  </div>
                </div>
              </div> -->

              <hr/>
              <div class="form-group">
                <div class="row">
                  <legend class="col-form-label col-sm-2 pt-0">Ukuran Seragam</legend>
                  <div class="col-sm-10">
                    <div class="form-check form-check-inline" v-for="(item, index) in listDetailUkuran" :key="index">
                      <input :readonly="readOnly" :disabled="biodata.jenis_kelamin == ''" @change="detailSeragam(index)" name="ukuran_seragam" v-validate="'required|included:XXS,XS,S,M,L,XL,XXL,XXXL,XXXXL'" v-model="biodata.pesertadidik.ukuran_seragam" class="form-check-input" type="radio" :id="item.id" :value="item.id">
                      <label class="form-check-label" :for="item.id">{{item.label}}</label>
                    </div>
                    <div v-if="biodata.jenis_kelamin == ''" class="invalid-feedback" style="display: block;">Silahkan pilih jenis kelamin terlebih dahulu!</div>

                    <div v-show="errors.has('ukuran_seragam')" class="invalid-feedback" style="display: block;" v-text="errors.first('ukuran_seragam')"></div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-1 mt-3" v-if="biodata.pesertadidik.ukuran_seragam">
                <div class="row">
                  <legend class="col-form-label col-sm-2 pt-0">Detail</legend>
                  <div class="col-sm-5">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Panjang Baju</th>
                          <th scope="col">Panjang Lengan</th>
                          <th scope="col">Lingkar Badan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td v-for="detail in useDetailSeragam" :key="detail">{{detail}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="form-group mb-1 mt-3">
                <div class="row">
                  <legend class="col-form-label col-sm-2 pt-0"></legend>
                  <div class="col-sm-10" style="color: #ff6700; font-size: 85%">
                    <strong>NOTE:</strong> Silahkan pilih ukuran seragam dengan hati-hati. Saat pemberian logistik, ukuran seragam sesuai yang dipilih dan tidak bisa diganti ke ukuran lain.
                  </div>
                </div>
              </div>

              <hr />
              <div class="form-group row mt-3">
                <label for="NISN" class="col-sm-2 col-form-label">Nomor Induk Siswa Nasional (NISN)</label>
                <div class="col-sm-10">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <input name="NISN" v-validate="'numeric|digits:10'" :class="{ 'is-invalid': errors.has('NISN') }" v-model="biodata.pesertadidik.nisn" type="text" class="form-control form-control-sm" id="NISN" placeholder="NISN">
                      <div v-show="errors.has('NISN')" class="invalid-feedback" v-text="errors.first('NISN')"></div>
                      <label for="NISN" style="color: #ccc; font-size: 11px; font-style: italic;">Opsional, diisi jika ada</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group mb-0">
                <div class="row">
                  <legend class="col-form-label col-sm-2 pt-0">Alumni dari lembaga pendidikan di NJ?</legend>
                  <div class="col-sm-10">
                    <div class="form-check form-check-inline">
                      <input name="is_alumni" v-validate="'required'" v-model="is_alumni" class="form-check-input" type="radio" id="T" :value="false">
                      <label class="form-check-label" for="T">Tidak</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input name="is_alumni" v-model="is_alumni" class="form-check-input" type="radio" id="Y" :value="true">
                      <label class="form-check-label" for="Y">Ya</label>
                    </div>
                    <div v-show="errors.has('is_alumni')" class="invalid-feedback" v-text="errors.first('is_alumni')"></div>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="is_alumni">
                <label for="niup" class="col-sm-2 col-form-label pl-4">&#x2022; NIUP</label>
                <div class="col-sm-10">
                  <div class="form-row align-items-center">
                    <div class="col-auto">
                      <input :readonly="readOnly" name="NIUP" v-validate="'required|numeric|digits:11'" :class="{ 'is-invalid': errors.has('NIUP') }" v-model="biodata.pesertadidik.niup" type="text" class="form-control form-control-sm" id="NIUP" placeholder="NIUP">
                      <div v-show="errors.has('NIUP')" class="invalid-feedback" v-text="errors.first('NIUP')"></div>
                      <label for="nisn" style="color: #ccc; font-size: 11px; font-style: italic;">wajib diisi jika alumni dari salah satu lembaga di NJ</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Akun Pendaftar -->
      <div class="card mb-4" style="border: none;">
        <h6 style="border-radius: unset;border: none;" class="card-header ">Akun Pendaftaran</h6>
        <div class="card-body">
          <div class="form-group row">
            <label for="email" class="col-sm-2 col-form-label">E-Mail</label>
            <div class="col-sm-10">
              <div class="form-row align-items-center">
                <div class="col-md-4">
                  <input type="email" name="E-Mail" v-validate="'required|email'" :class="{ 'is-invalid': errors.has('E-Mail') }" v-model="biodata.akun_pendaftar.email" :disabled="readOnly" id="email" class="form-control form-control-sm" placeholder="Masukan E-Mail"/>
                  <div v-show="errors.has('E-Mail')" class="invalid-feedback" v-text="errors.first('E-Mail')"></div>
                  <label for="email" style="color: #0c5460; font-size: 11px; font-style: italic;" >Email yang tidak valid, maka informasi akun pendaftaran tidak akan sampai ke Anda.</label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="notelp" class="col-sm-2 col-form-label">No. Telp</label>
            <div class="col-sm-10">
              <div class="form-row align-items-center">
                <div class="col-md-4">
                  <input type="text" name="No. Telp" v-validate="'required|min:6'" :class="{ 'is-invalid': errors.has('No. Telp') }" v-model="biodata.akun_pendaftar.phone" :disabled="readOnly" id="notelp" class="form-control form-control-sm" placeholder="+62xxxxxxxxxx"/>
                  <div v-show="errors.has('No. Telp')" class="invalid-feedback" v-text="errors.first('No. Telp')"></div>
                  <label for="notelp" style="color: #0c5460; font-size: 11px; font-style: italic;" >No. phone digunakan jika kami perlu menghubungi anda terkait pendaftaran yang telah anda kirim.</label>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div class="form-group row">
            <label for="password" class="col-sm-2 col-form-label">Kata Sandi</label>
            <div class="col-sm-10">
              <div class="form-row align-items-center">
                <div class="col-md-4">
                  <input type="password" name="Kata Sandi" v-validate="'required|min:6'" :class="{ 'is-invalid': errors.has('Kata Sandi') }" v-model="biodata.akun_pendaftar.password" :disabled="readOnly" id="password" class="form-control form-control-sm" placeholder="Masukan Kata Sandi" ref="Kata Sandi"/>
                  <div v-show="errors.has('Kata Sandi')" class="invalid-feedback" v-text="errors.first('Kata Sandi')"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="konfirm-password" class="col-sm-2 col-form-label">Ketik Ulang Kata Sandi</label>
            <div class="col-sm-10">
              <div class="form-row align-items-center">
                <div class="col-md-4">
                  <input type="password" name="Konfirmasi Kata Sandi" v-validate="'required|confirmed:Kata Sandi'" :class="{ 'is-invalid': errors.has('Konfirmasi Kata Sandi') }" id="konfirm-password" class="form-control form-control-sm" placeholder="Konfirmasi kata sandi" data-vv-as="Kata Sandi"/>
                  <div v-show="errors.has('Konfirmasi Kata Sandi')" class="invalid-feedback" v-text="errors.first('Konfirmasi Kata Sandi')"></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="mb-4">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <div class="px-3">
              <button @click="doRegister" class="btn btn-success" style="min-width: 180px;">Daftar</button>
            </div>
          </div>
        </div>
      </div>

      </div>

    </form>

    <div class="container mt-4" v-if="success.status">
      <div class="card mb-4" style="border: none;">
        <h6 style="border-radius: unset;border: none;" class="card-header text-white bg-primary text-center">Pendaftaran Sukses!</h6>
        <div class="card-body text-center">
          <p class="font-weight-bold text-secondary mb-0">No. Registrasi</p>
          <h2 class="card-title p-4" v-text="success.no_registrasi"></h2>
          <p class="card-text lead">Simpanlah No. Registrasi di atas, karena akan digunakan sebagai <b>username</b> pada saat login.<br></p>
          <div class="text-left p-4" style="display: inline-block;">
            <p>
              <b>Langkah Selanjutnya: </b>
              <ol class="mt-3">
                <li>Masuk dengan Nomor Registrasi dan Password yang telah di daftarkan.</li>
                <li>Validasi Data serta Pengisian identitas Orangtua / Wali</li>
                <li>Upload Berkas-berkas Pendukung</li>
              </ol>
            </p>
          </div>
          <div class="p-3">
            <router-link to="/login" target="_blank" class="btn btn-success">Login</router-link>
          </div>
          <div class="p-3">
            <router-link to="/" class="btn btn-outline-primary">Kembali ke Halaman Utama</router-link>
          </div>
        </div>
      </div>
    </div>
    <!--  Form Register End  -->
  </div>
</template>

<script>
import { load } from "recaptcha-v3"
import Tanggal from "../components/Tanggal";
import listDetailUkuran from "../plugins/api/listDetailUkuran";
export default {
  components: { Tanggal },
  data() { return {
    show_info: false,
    success: {
      status: false,
      deskripsi: '',
      no_registrasi: '',
      uuid_person: ''
    },
    alamat: {
      negara: [],
      provinsi: [],
      kabupaten: [],
      kecamatan: []
    },
    pendidikan: {
      lembaga: [],
      jurusan: [],
      kelas: []
    },
    biodata: {
      warganegara: 'WNI',
      no_passport: null,
      nik: null,
      nokk: null,
      nama_lengkap: '',
      tempat_lahir: '',
      tanggal_lahir: '',
      jenis_kelamin: '',
      anak_ke: 0,
      jum_saudara: 0,
      tinggal_bersama: '',
      pendidikan_terakhir: '',
      id_negara: null,
      id_provinsi: null,
      id_kabupaten: null,
      id_kecamatan: null,
      jalan: '',
      kodepos: '',
      akun_pendaftar: {
        phone: '',
        email: '',
        password: ''
      },
      pesertadidik: {
        nisn: null,
        niup: null,
        is_mondok: '',
        jenis_daftar: null,
        pendidikan: {
          id_lembaga: null,
          id_jurusan: null,
          id_kelas: null
        }
      }
    },
    listDetailUkuran: listDetailUkuran,
    useDetailSeragam:{},
    recaptcha_token: '',
    readOnly: false,
    is_alumni: false
  }},
  methods: {
    doRegister() {
      let doit = async () => {
        this.$store.commit('setLoading', true);
        let data = {
          warganegara: this.biodata.warganegara,
          no_passport: this.biodata.no_passport,
          nik: this.biodata.nik,
          nokk: this.biodata.nokk,
          nama_lengkap: this.biodata.nama_lengkap,
          tempat_lahir: this.biodata.tempat_lahir,
          tanggal_lahir: this.biodata.tanggal_lahir,
          jenis_kelamin: this.biodata.jenis_kelamin,
          anak_ke: parseInt(this.biodata.anak_ke),
          jum_saudara: parseInt(this.biodata.jum_saudara),
          tinggal_bersama: this.biodata.tinggal_bersama,
          pendidikan_terakhir: this.biodata.pendidikan_terakhir,
          id_kecamatan: this.biodata.id_kecamatan,
          id_kabupaten: this.biodata.id_kabupaten,
          jalan: this.biodata.jalan,
          kodepos: this.biodata.kodepos,

          akun_pendaftar: {
            email: this.biodata.akun_pendaftar.email,
            phone: this.biodata.akun_pendaftar.phone,
            password: this.biodata.akun_pendaftar.password
          },

          pesertadidik: {
            is_mondok: this.biodata.pesertadidik.is_mondok,
            jenis_daftar: this.biodata.pesertadidik.jenis_daftar,
            pendidikan: {
              id_lembaga: this.biodata.pesertadidik.pendidikan.id_lembaga,
              id_jurusan: this.biodata.pesertadidik.pendidikan.id_jurusan
            },
            ukuran_seragam: this.biodata.pesertadidik.ukuran_seragam,
            nisn: this.biodata.pesertadidik.nisn,
            niup: this.biodata.pesertadidik.niup,
          }
        };

          await this.generateToken();

        this.$axios.post('/pendaftar/register', data, {
          headers: { 'x-recaptcha-token' : this.recaptcha_token }
        })
        .then(res => {
          // console.log(res.data.);
          this.success.status = res.data.status;
          this.success.deskripsi = res.data.deskripsi;
          this.success.no_registrasi = res.data.no_registrasi;
          this.success.uuid_person = res.data.uuid_person;
          this.$store.commit('setLoading', false);
        }).catch(() => {
          this.$store.commit('setLoading', false);
          this.show_info = true;
        }).finally(() => {
          this.$store.commit('setLoading', false);
        })
      }
      this.$validator.validateAll().then(result => {
        if(result) {
          doit();
        } else {
          let invalidfields = this.$validator.errors.items;
          let messages = [];
          for(let i in invalidfields) {
            if(i < 5) // limit 5
              messages.push(`<p class="mb-0">${invalidfields[i].msg}</p>`);
          }
          if(invalidfields.length > 5) messages.push(`<p class="mb-0">...</p>`)

          this.$root.$emit('setNotif', {
            title: 'Harap melengkapi data!',
            message: `<div>${messages.join('')}</div>`,
            type: 'warning',
          });
        }
      });
    },
    handleNegara() {
      this.$psb.api.Select.handleNegara(this.biodata.id_negara, data => {
        this.biodata.id_provinsi = data.provinsi;
        this.biodata.id_kabupaten = data.kabupaten;
        this.biodata.id_kecamatan = data.kecamatan;
        this.alamat.provinsi = data.list_provinsi;
      });
    },
    handleProvinsi() {
      this.$psb.api.Select.handleProvinsi(this.biodata.id_provinsi, data => {
        this.biodata.id_kabupaten = data.kabupaten;
        this.biodata.id_kecamatan = data.kecamatan;
        this.alamat.kabupaten = data.list_kabupaten;
      });
    },
    handleKabupaten() {
      this.$psb.api.Select.handleKabupaten(this.biodata.id_kabupaten, data => {
        this.biodata.id_kecamatan = data.kecamatan;
        this.alamat.kecamatan = data.list_kecamatan;
      });
    },
    handleLembaga(mode) {
      if(this.biodata.pesertadidik.pendidikan.id_lembaga) {
        this.$axios.get('/setting/lembaga/jurusan?lembaga=' + this.biodata.pesertadidik.pendidikan.id_lembaga)
        .then(res => {
          this.pendidikan.jurusan = res.data;
        }).catch(() => {});
        this.$axios.get('/setting/lembaga/kelas?lembaga=' + this.biodata.pesertadidik.pendidikan.id_lembaga)
        .then(res => {
          this.pendidikan.kelas = res.data;
        }).catch(() => {});
        if(mode == 'new') {
          this.biodata.pesertadidik.pendidikan.id_kelas = null;
          this.biodata.pesertadidik.pendidikan.id_jurusan = null;
        }
      } else {
        this.pendidikan.jurusan = [];
        this.pendidikan.kelas = [];
        this.biodata.pesertadidik.pendidikan.id_kelas = null;
        this.biodata.pesertadidik.pendidikan.id_jurusan = null;
      }
    },
    async generateToken() {
      this.$recaptcha = {};
      load(this.$psb.config.recaptchaKey, {
        useRecaptchaNet: true
      }).then(recaptcha => {
        let gb = document.querySelector('.grecaptcha-badge');
        if(gb) gb.setAttribute('class', 'grecaptcha-badge');

        recaptcha.execute()
        .then(token => {
          this.recaptcha_token = token;
        }).catch(() => {});
        this.$recaptcha = recaptcha;
      }).catch(() => {});
    },
    onJkChange() {
      this.biodata.pesertadidik.ukuran_seragam = "",
      this.useDetailSeragam = {}
    },
    detailSeragam(id) {
      if(this.biodata.jenis_kelamin == "L") {
        this.useDetailSeragam = this.listDetailUkuran[id]?.putra
      }else{
        this.useDetailSeragam = this.listDetailUkuran[id]?.putri
      }
    }
  },
  beforeDestroy() {
    let gb = document.querySelector('.grecaptcha-badge');
    if(gb) gb.setAttribute('class', 'grecaptcha-badge d-none');
  },
  mounted() {
    this.generateToken();
    this.$root.$emit('setNotif', {
            title: 'Perhatian!',
            message: `<div class="h5">Bagi mahasiswa baru yang mondok untuk mendaftar di <a href="https://pmb.unuja.ac.id/" target="_blank" class="font-weight-bold">PMB UNUJA</a></div>`,
            type: 'info',
          });
    this.$axios.get('/setting/negara')
    .then(res => {
      this.alamat.negara = res.data;
    }).catch(() => {});

    this.$axios.get('/setting/lembaga')
    .then(res => {
      this.pendidikan.lembaga = res.data;
    }).catch(() => {});
  }
};
</script>

<style lang="scss">
@import "../assets/css/landing.scss";
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
